import { gql } from '@apollo/client';
import { FILE_URL_FRAGMENT } from '../file';
export const ORGANIZATION_WITH_IMPACT_QUERY = gql `
  ${FILE_URL_FRAGMENT}

  query OrganizationImpactByID($organizationID: ID!, $userID: ID!) {
    organization: vizslaOrganization(id: $organizationID) {
      id
      name
      ein
      contactEmail

      logo {
        ...FileURLFragment
      }
    }

    registrations: attendeesList(
      sort: { createdAt: ASC }
      filter: {
        AND: [
          { experience: { campaign: { vizslaOrganization: { id: { equals: $organizationID } } } } }
        ]
      }
    ) {
      items {
        id
        createdAt
      }
    }

    totalRaised: donationAllocationsList(
      filter: { AND: [{ campaign: { vizslaOrganization: { id: { equals: $organizationID } } } }] }
      groupBy: { query: { amount: { as: "totalRaised", fn: { aggregate: SUM } } } }
    ) {
      groups {
        totalRaised: Int
      }
    }

    averageDonation: donationAllocationsList(
      filter: {
        AND: [
          { campaign: { vizslaOrganization: { id: { equals: $organizationID } } } }
          { donationFulfillment: { donor: { id: { equals: $userID } } } }
        ]
      }
      groupBy: { query: { amount: { as: "averageDonation", fn: { aggregate: AVG } } } }
    ) {
      groups {
        averageDonation: Int
      }
    }

    totalDonations: donationAllocationsList(
      filter: {
        AND: [
          { campaign: { vizslaOrganization: { id: { equals: $organizationID } } } }
          { donationFulfillment: { donor: { id: { equals: $userID } } } }
        ]
      }
      groupBy: { query: { amount: { as: "totalDonations", fn: { aggregate: SUM } } } }
    ) {
      groups {
        totalDonations: Int
      }
    }
  }
`;
