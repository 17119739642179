/* eslint-disable no-useless-escape */
const EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const BRACKETS = /[\])}[{(]/g;
const URL = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i;
const TEMPLATE_PARSER_BRACKETS = /\{\{[ ]{0,}([\w\_-]{1,})[ ]{0,}\}\}/gi;
const DISCOUNT_NAME = /(?=.*\d)(?!.*[a-z])(?=.*[A-Z])(?!.*[-+_!@#$%^&*()\[\]{}`~|\\\/><:;'"., ?])/gm;
const NOT_SPECIAL_SYMBOLS = /^[a-zA-Z0-9]+$/;
const ONLY_CAPS = /^[^a-z]+$/;
const HAS_AT_LEAST_ONE_DIGIT = /\d/;
const NAN = /[^\d]/g;
const SPECIAL_SYMBOLS_REG_EXP = /[^a-zA-Z0-9 -]/g;
export const ID_8BASE = /^c[a-z0-9]{24}$/;
const WEB_LINK = /^[a-zA-Z0-9_][a-zA-Z0-9-_]*[a-zA-Z0-9_]$/;
export { EMAIL, BRACKETS, URL, TEMPLATE_PARSER_BRACKETS, DISCOUNT_NAME, SPECIAL_SYMBOLS_REG_EXP, WEB_LINK, NAN, NOT_SPECIAL_SYMBOLS, ONLY_CAPS, HAS_AT_LEAST_ONE_DIGIT, };
