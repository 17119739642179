import { gql } from '@apollo/client';
export const DONOR_COMMITMENT_FRAGMENT = gql `
  fragment DonorCommitmentFragment on DonorCommitment {
    id
    createdAt
    status
    type
    amount
    company
    fundraiser {
      id
      title
      attendee {
        user {
          firstName
          lastName
        }
      }
    }
    team {
      id
      name
    }
    donor {
      id
      fullName
      email
    }
    experience {
      id
      name
    }
    campaign {
      id
      name
      vizslaOrganization {
        id
      }
    }
  }
`;
export const DONOR_COMMITMENTS_LIST_QUERY = gql `
  query DonorCommitmentsList($vizlaOrganizationID: ID) {
    donorCommitmentsList(
      filter: { campaign: { vizslaOrganization: { id: { equals: $vizlaOrganizationID } } } }
    ) {
      items {
        ...DonorCommitmentFragment
      }
    }
  }
  ${DONOR_COMMITMENT_FRAGMENT}
`;
export const DONOR_COMMITMENT_CREATE_MUTATION = gql `
  mutation DonorCommitmentCreate($data: DonorCommitmentCreateInput!) {
    donorCommitmentCreate(data: $data) {
      ...DonorCommitmentFragment
    }
  }
  ${DONOR_COMMITMENT_FRAGMENT}
`;
export const DONOR_COMMITMENT_UPDATE_MUTATION = gql `
  mutation DonorCommitmentUpdate(
    $data: DonorCommitmentUpdateInput!
    $filter: DonorCommitmentKeyFilter!
  ) {
    donorCommitmentUpdate(data: $data, filter: $filter) {
      ...DonorCommitmentFragment
    }
  }
  ${DONOR_COMMITMENT_FRAGMENT}
`;
