import { gql } from '@apollo/client';
import { FILE_URL_FRAGMENT } from '../file';
import { USER_FRAGMENT } from '../users';
export const TEAM_FUNDRAISING_FRAGMENT = gql `
  fragment TEAM_FUNDRAISING_FRAGMENT on TeamFundraising {
    id
    name
    teamMissionTitle

    avatar {
      ...FileURLFragment
    }

    teamPhoto {
      ...FileURLFragment
    }

    members {
      items {
        role
        attendee {
          user {
            ...UserFragment
          }
        }
      }
    }
  }
`;
export const TEAM_MEMBERSHIP_FRAGMENT = gql `
  fragment TEAM_MEMBERSHIP_FRAGMENT on CampaignTeamMember {
    id
    role

    team {
      ...TEAM_FUNDRAISING_FRAGMENT
    }
  }
`;
export const TEAM_FUNDRAISING_BY_EXPERIENCE_QUERY = gql `
  ${USER_FRAGMENT}
  ${FILE_URL_FRAGMENT}
  ${TEAM_FUNDRAISING_FRAGMENT}

  query TeamFundraisingByExperienceID($experienceID: ID!) {
    teams: teamFundraisingsList(filter: { experience: { id: { equals: $experienceID } } }) {
      items {
        ...TEAM_FUNDRAISING_FRAGMENT
      }
    }
  }
`;
export const LEAVE_TEAM_FUNDRAISING_MUTATION = gql `
  mutation LeaveTeamFundraising($attendeeId: ID!, $teamId: ID!) {
    removal: campaignTeamMemberDeleteByFilter(
      filter: { team: { id: { equals: $teamId } }, attendee: { id: { equals: $attendeeId } } }
    ) {
      success
    }
  }
`;
export const TEAM_FUNDRAISING_RAISED = gql `
  query TeamFundraisingRaised($experienceId: ID!) {
    teamFundraisingsList(filter: { experience: { id: { equals: $experienceId } } }) {
      count
      items {
        totalRaised
        name
        id
        webLink
        avatar {
          id
          downloadUrl
        }
      }
    }
  }
`;
