import { gql } from '@apollo/client';
export const INVENTORY_ITEM = gql `
  query inventoriesListById($filter: InventoryFilter!) {
    inventoriesList(filter: $filter) {
      items {
        unitPrice
        storeAssetId
        OrgID
        id
        sales
        profit
        refund
        priceSales
        storeAssetName
        variantsValues
      }
    }
  }
`;
