import { gql } from '@apollo/client';
export const TRANSACTION_BILLING = gql `
  query transactionBilling($orderId: ID!) {
    order(id: $orderId) {
      id
      payment {
        billingInfo
        id
      }
      parent {
        id
        payment {
          id
          billingInfo
        }
      }
      totalAmount
      _description
      orderItems {
        items {
          amount
          category
          transactionType
          description
          comment
          id
          assetFulfillment {
            storeInventoryItem {
              id
              storeAsset {
                name
              }
              variantsValues
            }
          }
          assetSwagbagFulfillment {
            storeInventoryItem {
              id
              storeAsset {
                name
              }
              variantsValues
            }
          }
        }
      }
      registrationOptionFulfillment {
        items {
          id
        }
      }
      user {
        _description
        email
        firstName
        lastName
        fullName
        companyName
        gender
        createdAt
        phone {
          number
          code
        }
        customer
        address {
          city
          country
          state
          street1
          street2
          zip
        }
      }
      donations {
        items {
          donationAllocations {
            items {
              transferedFrom {
                id
              }
              receivedBy {
                id
              }
            }
          }
        }
      }
    }
  }
`;
