import { gql } from '@apollo/client';
export const REPORT_FOLDER_FRAGMENT = gql `
  fragment ReportFolder on ReportFolder {
    id
    name
  }
`;
export const REPORT_FOLDERS_QUERY = gql `
  query ReportFolders($filter: ReportFolderFilter, $first: Int, $skip: Int) {
    folders: reportFoldersList(filter: $filter, first: $first, skip: $skip) {
      count
      items {
        ...ReportFolder
      }
    }
  }

  ${REPORT_FOLDER_FRAGMENT}
`;
export const REPORT_FOLDER_CREATE_MUTATION = gql `
  mutation ReportFolderCreate($data: ReportFolderCreateInput!) {
    folder: reportFolderCreate(data: $data) {
      ...ReportFolder
    }
  }

  ${REPORT_FOLDER_FRAGMENT}
`;
export const REPORT_FOLDER_CREATE_MANY_MUTATION = gql `
  mutation ReportFolderCreateMany($data: [ReportFolderCreateManyInput!]!) {
    folders: reportFolderCreateMany(data: $data) {
      count
      items {
        ...ReportFolder
      }
    }
  }

  ${REPORT_FOLDER_FRAGMENT}
`;
export const REPORT_FOLDER_UPDATE_MUTATION = gql `
  mutation ReportFolderUpdate($filter: ReportFolderKeyFilter, $data: ReportFolderUpdateInput!) {
    folder: reportFolderUpdate(filter: $filter, data: $data) {
      ...ReportFolder
    }
  }

  ${REPORT_FOLDER_FRAGMENT}
`;
export const REPORT_FOLDER_UPDATE_MANY_MUTATION = gql `
  mutation ReportFolderUpdateMany(
    $filter: ReportFolderFilter
    $data: ReportFolderUpdateByFilterInput!
  ) {
    folders: reportFolderUpdateByFilter(filter: $filter, data: $data) {
      count
      items {
        ...ReportFolder
      }
    }
  }

  ${REPORT_FOLDER_FRAGMENT}
`;
