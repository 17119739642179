export const BASE_NUMBER_FORMAT = {
    thousandSeparator: true,
};
export const MONEY_FORMAT = {
    thousandSeparator: true,
    prefix: '$',
};
export const DECIMAL_MONEY_FORMAT = {
    thousandSeparator: true,
    prefix: '$',
    decimalScale: 2,
    fixedDecimalScale: true,
};
export const PERCENT_FORMAT = {
    thousandSeparator: true,
    suffix: '%',
};
export const YEAR_FORMAT = {
    format: '####',
};
export const COUNT_FORMAT = {
    thousandSeparator: true,
};
export const RATIO_FORMAT = {
    thousandSeparator: true,
    suffix: 'x',
};
export const MONTH_FORMAT = {
    thousandSeparator: true,
    suffix: ' months',
};
export const ZIPCODE_MASK_WILDCARD = '#';
export const ZIPCODE_REGEX = /\d{5}/i;
export const ZIPCODE_MASK = '#####';
export const PHONE_REGEX = /\d{10}/i;
export const PHONE_MASK_WILDCARD = '#';
export const PHONE_MASK = '+1 (###) ###-####';
