import { gql } from '@apollo/client';
export const USER_FRAGMENT = gql `
  fragment UserFragment on User {
    id
    email
    firstName
    lastName
    fullName
    status
    customer
    birthDate
    minor
    gender

    vizslaOrganizationStaff {
      count
      items {
        id
        roles
      }
    }

    phone {
      code
      number
    }

    address {
      street1
      street2
      state
      city
      zip
    }

    avatar {
      id
      fileId
      downloadUrl
    }
  }
`;
export const USER_FRAGMENT_ORGANIZATION = gql `
  fragment UserFragmentOrganization on User {
    id
    email
    firstName
    lastName
    fullName
    status
    customer
    birthDate
    minor
    gender

    phone {
      code
      number
    }

    address {
      street1
      street2
      state
      city
      zip
    }

    avatar {
      id
      fileId
      downloadUrl
    }

    vizslaOrganizationStaff {
      items {
        id
        vizslaOrganization {
          id
        }
      }
    }
  }
`;
export const USERS_LIST_QUERY = gql `
  query UsersList($filter: UserFilter, $first: Int, $skip: Int) {
    usersList(filter: $filter, first: $first, skip: $skip) {
      items {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;
export const USERS_LIST_ORGANIZATION_QUERY = gql `
  query UsersListOrganization($filter: UserFilter, $first: Int, $skip: Int) {
    usersList(filter: $filter, first: $first, skip: $skip) {
      items {
        ...UserFragmentOrganization
      }
    }
  }
  ${USER_FRAGMENT_ORGANIZATION}
`;
export const USER_ATTENDEE_EXPERIENCE = gql `
  query userAttendeeExperience($userId: ID!, $experienceId: ID!) {
    attendeesList(
      filter: { user: { id: { equals: $userId } }, experience: { id: { equals: $experienceId } } }
    ) {
      items {
        id
        user {
          firstName
          lastName
        }
        experience {
          id
          name
        }
        teamMembership {
          id
          team {
            name
          }
        }
      }
    }
  }
`;
export const INDIVIDUAL_FUNDRAISING_BY_ATTENDEE = gql `
  query individualFundraisingByAttendee($filter: AttendeeFilter!) {
    attendeesList(filter: $filter) {
      items {
        fundraising {
          id
        }
        experience {
          id
          name
        }
      }
    }
  }
`;
export const CURRENT_USER_QUERY = gql `
  query User {
    user {
      id
      email
      firstName
      lastName
      currentApplication
      hasAlreadyLoggedIn
      currentOrganization {
        id
      }
      phone {
        code
        number
      }
      address {
        city
        state
        street1
        street2
        zip
        country
      }
      status
      customer
      attendees {
        count
        items {
          teamMembership {
            team {
              name
            }
          }
        }
      }
      avatar {
        id
        downloadUrl
      }
      roles {
        items {
          id
          name
        }
      }
      vizslaOrganizationStaff {
        items {
          id
          roles
          vizslaOrganization {
            id
          }
        }
      }
      minor
      parent {
        id
        firstName
        lastName
        fullName
        email
      }
      childrens {
        items {
          id
          firstName
          lastName
          fullName
          email
        }
      }
    }
  }
`;
export const USER_SIGNUP_MUTATION = gql `
  mutation UserSignup($user: UserCreateInput!) {
    userSignUpWithToken(user: $user) {
      id
      email
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
    }
  }
`;
export const CHANGE_USER_CURRENT_APPLICATION_MUTATION = gql `
  mutation ChangeUserCurrentApp($userId: ID!, $applicationId: String!) {
    userUpdate(filter: { id: $userId }, data: { currentApplication: $applicationId }) {
      id
      currentApplication
    }
  }
`;
export const CURRENT_ATTENDEE_QUERY = gql `
  query currentAttendee($attendeeid: ID) {
    attendee(id: $attendeeid) {
      user {
        id
        email
        firstName
        lastName

        avatar {
          id
          downloadUrl
        }
        gender
        createdAt
        phone {
          code
          number
        }
        birthDate
        address {
          city
          country
          state
          street1
          street2
          zip
        }
      }
    }
  }
`;
export const GET_USER_BY_EMAIL = gql `
  query getUserByEmail($email: String!) {
    user(email: $email) {
      id
      email
      firstName
      lastName
      fullName
      gender
      birthDate
      status
      phone {
        code
        number
      }
      address {
        city
        state
        country
        zip
        street1
        street2
      }
      avatar {
        id
        downloadUrl
      }
      roles {
        items {
          id
          name
        }
      }
      vizslaOrganizationStaff {
        items {
          id
          roles
          vizslaOrganization {
            id
          }
        }
      }
    }
  }
`;
export const GET_USER_LIST_FROM_ORGANIZATION = gql `
  query getUserListFromOrganization($filter: UserFilter, $first: Int, $skip: Int) {
    usersList(filter: $filter, first: $first, skip: $skip) {
      count
      items {
        id
        email

        attendees(filter: { id: { is_empty: false } }) {
          count
        }
      }
    }
  }
`;
