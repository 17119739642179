export var RouterEntitiesId;
(function (RouterEntitiesId) {
    RouterEntitiesId["organization"] = "organizationId";
    RouterEntitiesId["campaign"] = "campaignId";
    RouterEntitiesId["experience"] = "experienceId";
    RouterEntitiesId["storeAsset"] = "storeAssetId";
    RouterEntitiesId["fundraiser"] = "fundraiserId";
    RouterEntitiesId["donationOption"] = "donationOptionId";
    RouterEntitiesId["order"] = "orderId";
    RouterEntitiesId["orderIntent"] = "orderIntentId";
    RouterEntitiesId["partnership"] = "partnershipId";
    RouterEntitiesId["user"] = "userId";
    RouterEntitiesId["check"] = "check";
    RouterEntitiesId["attendee"] = "attendeeId";
    RouterEntitiesId["report"] = "reportId";
})(RouterEntitiesId || (RouterEntitiesId = {}));
/** @deprecated Use {@link LANDING_ROUTES} instead. */
export var LandingRoutes;
(function (LandingRoutes) {
    /** @deprecated Use {@link LandingRoutes.EXPERIENCE} instead. */
    LandingRoutes["experience"] = "/experience/:experienceId";
    /** @deprecated Use {@link LandingRoutes.EXPERIENCE_ATTENDEES} instead. */
    LandingRoutes["experienceAttendeeRegistration"] = "/experience/:experienceId/registration";
    /** @deprecated */
    LandingRoutes["experienceAttendeeRegistrationFinal"] = "/experience/:experienceId/registration/final";
    /** @deprecated Use {@link LandingRoutes.EXPERIENCE_ATTENDEES} instead. */
    LandingRoutes["experienceAttendeeRegistrationTesting"] = "/experience/:experienceId/registration/testing";
    /** @deprecated Use {@link LandingRoutes.EXPERIENCE_DONATION} instead. */
    LandingRoutes["experienceDonation"] = "/experience/:experienceId/donation";
    /** @deprecated */
    LandingRoutes["experienceDonationFinal"] = "/experience/:experienceId/donation/final";
    LandingRoutes["fundraiser"] = "/fundraiser/:fundraiserId";
    LandingRoutes["fundraiserDonation"] = "/fundraiser/:fundraiserId/donation";
    LandingRoutes["fundraiserDonationThanks"] = "/fundraiser/:fundraiserId/donation/thanks";
    LandingRoutes["EXPERIENCE"] = "/experience/:experienceId";
    /** This route uses the experienceId because all components belong to an experience created by default */
    LandingRoutes["CAMPAIGN"] = "/campaign/:experienceId";
    LandingRoutes["EXPERIENCE_ATTENDEES"] = "/experience/:experienceId/registration";
    LandingRoutes["EXPERIENCE_DONATION"] = "/experience/:experienceId/donation";
    /** Used to create the confirm the purchase and create the payment intent. */
    LandingRoutes["EXPERIENCE_CHECKOUT"] = "/experience/:experienceId/checkout";
    LandingRoutes["EXPERIENCE_PAYMENT"] = "/experience/:experienceId/payment";
    LandingRoutes["EXPERIENCE_THANKS"] = "/experience/:experienceId/thanks";
    /** @deprecated Use {@link LandingRoutes.TEAM} instead. */
    LandingRoutes["team"] = "/team/:teamId";
    LandingRoutes["TEAM"] = "/team/:teamId";
    LandingRoutes["TEAM_DONATION"] = "/team/:teamId/donate";
    LandingRoutes["TEAM_DONATION_PAYMENT"] = "/team/:teamId/donate/payment";
    LandingRoutes["TEAM_DONATION_THANKS"] = "/team/:teamId/donate/thanks";
})(LandingRoutes || (LandingRoutes = {}));
/**
 * Defines the route of some pages landing pages. With this, you can use the right route and generate correctly the final public URL.
 * @example
 * ```tsx
 * <Route path={LANDING_ROUTES.experience}>
 *  <MyAwesomeRoute />
 * </Route>
 * ```
 * */
export * as LANDING_ROUTES from './landingRoutes';
export const LandingStartRoutes = [
    LandingRoutes.experience,
    LandingRoutes.experienceAttendeeRegistration,
    LandingRoutes.fundraiser,
    LandingRoutes.fundraiserDonation,
    LandingRoutes.team,
];
