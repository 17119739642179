import { gql } from '@apollo/client';
import { FILE_URL_FRAGMENT } from '../file';
import { USER_FRAGMENT } from '../users';
import { INDIVIDUAL_FUNDRAISING_MANAGEMENT_FRAGMENT } from '../individualFundraising';
import { TEAM_MEMBERSHIP_FRAGMENT, TEAM_FUNDRAISING_FRAGMENT } from '../teamFundraising';
const INDIVIDUAL_FUNDRAISER_TEMPLATE_TYPE = 'INDIVIDUAL_FUNDRAISER';
const ATTENDEE_FRAGMENT = gql `
  fragment ATTENDEE_FRAGMENT on Attendee {
    id
    waiverSignature

    user {
      ...UserFragment

      avatar {
        ...FileURLFragment
      }
    }

    registrationOption {
      id
      name
      description
      fundraisingMinimum
    }

    teamMembership {
      ...TEAM_MEMBERSHIP_FRAGMENT
    }

    waiverSignedBy {
      id
      email
      firstName
      lastName
    }

    experience {
      id
      name
      startDate
      endDate
      waiverEnabled
      waiverAllowMultipleAttendeeConsent
      waiverTextBody
      timeZone

      venues {
        items {
          id
          name
          address
          notes
        }
      }

      experienceLogoImage {
        ...FileURLFragment
      }

      experienceHeaderImage {
        ...FileURLFragment
      }

      fundraisingSettings {
        id

        generalSettings {
          id
          facebookPage
          isFacebookEnabled
        }
      }

      campaign {
        id
        name
        contactEmail
        city
        state

        vizslaOrganization {
          name
          id
          timeZone
        }

        contactPhone {
          code
          number
        }

        logo {
          ...FileURLFragment
        }

        dnsRecord {
          slug
        }

        campaignTemplates {
          templates(filter: { templateType: { type: { equals: "${INDIVIDUAL_FUNDRAISER_TEMPLATE_TYPE}" } } }) {
            items {
              structure
            }
          }
        }
      }
    }
  }
`;
export const ATTENDEE_SIGN_WAIVER_MUTATION = gql `
  mutation AttendeeSignWaiver(
    $id: ID!
    $waiverSignature: String!
    $waiverSignedBy: AttendeeWaiverSignedByUpdateRelationInput!
    $waiverSignedAt: DateTime!
  ) {
    attendee: attendeeUpdate(
      data: {
        id: $id
        waiverSignature: $waiverSignature
        waiverSignedBy: $waiverSignedBy
        waiverSignedAt: $waiverSignedAt
      }
    ) {
      id
    }
  }
`;
export const ATTENDEE_MANAGEMENT_QUERY = gql `
  ${FILE_URL_FRAGMENT}
  ${USER_FRAGMENT}
  ${TEAM_FUNDRAISING_FRAGMENT}
  ${TEAM_MEMBERSHIP_FRAGMENT}
  ${INDIVIDUAL_FUNDRAISING_MANAGEMENT_FRAGMENT}
  ${ATTENDEE_FRAGMENT}

  query AttendeeManagement($id: ID!) {
    attendee(id: $id) {
      ...ATTENDEE_FRAGMENT

      fundraising {
        ...INDIVIDUAL_FUNDRAISING_MANAGEMENT_FRAGMENT
      }
    }
  }
`;
export const REGISTER_ATTENDEES = gql `
  mutation ShoppingCartRegisterAttendees($data: [ShoppingCartRegisterAttendeesManyInput!]!) {
    attendees: shoppingCartRegisterAttendees(data: $data) {
      items {
        id
        user {
          id
        }
      }
    }
  }
`;
export const ATTENDEES_BY_EXPERIENCE_LANDING = gql `
  query AttendeesByExperienceLanding($id: ID!, $first: Int, $skip: Int) {
    experienceLandingPeopleSectionAttendees(experienceId: $id, first: $first, skip: $skip) {
      items {
        createdAt
        id
        user {
          id
          firstName
          lastName
          email
          avatar {
            downloadUrl
          }
        }
        registrationOption {
          id
          name
        }
      }
    }
  }
`;
export const GET_ATTENDEES_TOTAL_RAISED = gql `
  query getAttendeesTotalRaisedByTeam($teamId: String!, $attendeeId: String!) {
    getAttendeesTotalRaisedByTeam(data: { teamId: $teamId, attendeeId: $attendeeId }) {
      items {
        amount
        id
        donor {
          fullName
          id
        }
      }
    }
  }
`;
