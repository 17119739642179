import { gql } from '@apollo/client';
export const UNALLOCATED_DONATION_RESUME_QUERY = gql `
  query UnallocatedDonationResume($filter: DonationAllocationFilter!) {
    metrics: donationAllocationsList(
      filter: $filter

      groupBy: {
        query: {
          amount: { as: "total", fn: { aggregate: SUM } }
          id: { as: "count", fn: { aggregate: COUNT } }
        }
      }
    ) {
      donations: items {
        id

        campaign {
          id
        }

        experience {
          id
        }

        teamFundraising {
          id
        }
      }

      groups {
        total: Float
        count: Int
      }
    }
  }
`;
export const ALLOCATED_DONATIONS_METRICS_QUERY = gql `
  query AllocatedDonationsMetrics($filter: DonationAllocationFilter!) {
    donations: donationAllocationsList(
      filter: $filter
      groupBy: {
        query: {
          createdAt: { as: "date", fn: { datePart: DATE } }
          amount: { as: "amount", fn: { aggregate: SUM } }
          id: { as: "count", fn: { aggregate: COUNT } }
        }
      }
    ) {
      groups {
        date: Date
        amount: Float
        count: Int
      }
    }
  }
`;
export const ALLOCATED_DONATIONS_QUERY = gql `
  query AllocatedDonations($filter: DonationAllocationFilter!, $skip: Int, $first: Int) {
    donations: donationAllocationsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        amount
        createdAt

        individualFundraising {
          id
          attendee {
            user {
              firstName
              lastName
            }
          }
        }

        parents {
          count
        }

        teamFundraising {
          id
          name
        }

        donor {
          firstName
          lastName

          avatar {
            downloadUrl
          }

          allocatedDonations(filter: $filter, first: 1, orderBy: createdAt_ASC) {
            items {
              id
            }
          }
        }
      }
    }
  }
`;
