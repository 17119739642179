/**
 * Store all the available report names.
 * @ticket https://8base-dev.atlassian.net/browse/VSL-2103
 */
export var ReportName;
(function (ReportName) {
    // https://8base-dev.atlassian.net/browse/VSL-2109
    ReportName["People"] = "PEOPLE";
    ReportName["Teams"] = "TEAMS";
    ReportName["Transactions"] = "TRANSACTIONS";
    ReportName["Experiences"] = "EXPERIENCES";
    ReportName["Ateendees"] = "ATTENDEES";
    ReportName["Campaigns"] = "CAMPAIGNS";
    ReportName["Atteendees"] = "ATTENDEES";
    ReportName["Merchandise"] = "MERCHANDISE";
    ReportName["Donations"] = "DONATIONS";
    ReportName["DonationImpactByYear"] = "DONATION IMPACT BY YEAR";
    ReportName["DonationsImpactByMonth"] = "DONATIONS IMPACT BY MONTH";
    ReportName["MedianDonationsMonth"] = "MEDIAN_DONATIONS_MONTH";
    ReportName["FailingRecurringGivingPlans"] = "FAILING RECURRING GIVING PLANS";
    ReportName["RevenueCategoriesByMonthReportPage"] = "REVENUE CATEGORIES BY MONTH REPORTER";
    ReportName["DonationsSources"] = "DONATIONS_SOURCES";
    ReportName["RevenueCategoriesbyYear"] = "REVENUE CATEGORIES BY YEAR";
    ReportName["CumulativeDonationGrowth"] = "CUMULATIVE DONATION GROWTH";
    ReportName["ActiveCampaignsAndExperiences"] = "ACTIVE CAMPAIGNS & EXPERIENCES";
    ReportName["Partnerships"] = "PARTNERSHIPS";
    ReportName["WeeksComparisonAttendeesRevenueAndTeams"] = "WEEKS COMPARISON ATTENDEES REVENUE AND TEAMS";
    ReportName["ComparisonExperiences"] = "COMPARISON EXPERIENCES";
    ReportName["DonorsPerFundraiserByMonth"] = "DONORS PER FUNDRAISER BY MONTH";
    ReportName["DonationsByMonth"] = "DONATIONS BY MONTH";
    ReportName["ActiveUsersByType"] = "ACTIVE USERS BY TYPE";
    ReportName["AdoptionByOrganization"] = "ADOPTION BY ORGANIZATION";
    ReportName["NonProfitRevenueSources"] = "NON PROFIT REVENUE SOURCES";
})(ReportName || (ReportName = {}));
/**
 * Store all the available report statuses.
 * @ticket https://8base-dev.atlassian.net/browse/VSL-2103
 */
export var ReportStatus;
(function (ReportStatus) {
    // Initial state for a report, when there's no data yet.
    ReportStatus["Empty"] = "EMPTY";
    // When the report is currently blocked because it is being generated.
    ReportStatus["Generating"] = "GENERATING";
    // Report is ready to be used! 🥳
    ReportStatus["Ready"] = "READY";
    // Something went wrong with the report generation.
    ReportStatus["Error"] = "ERROR";
})(ReportStatus || (ReportStatus = {}));
/**
 * Store all the available report data-source formats.
 * @ticket https://8base-dev.atlassian.net/browse/VSL-2103
 */
export var ReportDataSourceFormat;
(function (ReportDataSourceFormat) {
    ReportDataSourceFormat["CSV"] = "CSV";
    ReportDataSourceFormat["JSON"] = "JSON";
    ReportDataSourceFormat["XML"] = "XML";
    ReportDataSourceFormat["PDF"] = "PDF";
})(ReportDataSourceFormat || (ReportDataSourceFormat = {}));
/**
 * Store options to boolean values.
 * @ticket https://8base-dev.atlassian.net/browse/VSL-2103
 */
export const BinaryOptions = [
    {
        label: 'YES',
        value: 'true',
    },
    {
        label: 'NO',
        value: 'false',
    },
];
