export const EXPERIENCE = `/experience/:experienceId`;
/** This route uses the experienceId because all components belong to an experience created by default */
export const CAMPAIGN = `/campaign/:experienceId`;
export const EXPERIENCE_OPTIONS = `/experience/:experienceId/options`;
export const EXPERIENCE_ATTENDEES = `/experience/:experienceId/attendees`;
export const EXPERIENCE_SWAGBAGS = `/experience/:experienceId/swagbag`;
export const EXPERIENCE_ASSETS = `/experience/:experienceId/assets`;
export const EXPERIENCE_TEAM = `/experience/:experienceId/team`;
export const EXPERIENCE_DONATION = `/experience/:experienceId/donation`;
export const EXPERIENCE_THANKS = `/experience/:experienceId/thanks`;
export const EXPERIENCE_BILLING_ADDRESS = `/experience/:experienceId/billing`;
export const EXPERIENCE_PAYMENT = `/experience/:experienceId/payment`;
export const EXPERIENCE_REGISTRATION = EXPERIENCE_OPTIONS;
export const TEAM = '/team/:teamId';
export const TEAM_DONATION = `/team/:teamId/donate`;
export const TEAM_DONATION_THANKS = `/team/:teamId/donate/thanks`;
export const FUNDRAISER = '/fundraiser/:fundraiserId';
export const FUNDRAISER_DONATION = `/fundraiser/:fundraiserId/donate`;
export const FUNDRAISER_DONATION_THANKS = `/fundraiser/:fundraiserId/donate/thanks`;
