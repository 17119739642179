import { gql } from '@apollo/client';
export const QUESTIONS_DONATION = gql `
  query questionsDonation($orderItemId: ID!) {
    orderItem(id: $orderItemId) {
      id
      donationFulfillments {
        customQuestionAnswers {
          items {
            id
            question {
              id
              title
              options
              type
              format
              category
              order
              isRequiredAttendee
              isRequiredDonor
              isRequiredTeam
            }
            text
          }
        }
      }
    }
  }
`;
