import { DonationsReportTransactionType, DonationsReportDonationType, DonationsReportSourceType, } from '@vizsla/types';
export const DONATIONS_REPORT_TRANSACTION_TYPE_OPTIONS = [
    {
        label: 'Donation Payment',
        value: DonationsReportTransactionType.DonationPayment,
    },
    {
        label: 'Donation Pledge',
        value: DonationsReportTransactionType.DonationPledge,
    },
    {
        label: 'Allocation',
        value: DonationsReportTransactionType.Allocation,
    },
];
export const DONATIONS_REPORT_DONATION_TYPE_OPTIONS = [
    {
        label: 'One-Time',
        value: DonationsReportDonationType.OneTime,
    },
    {
        label: 'Recurring',
        value: DonationsReportDonationType.Recurring,
    },
];
export const DONATIONS_REPORT_DONATION_SOURCE_OPTIONS = [
    {
        label: 'Online',
        value: DonationsReportSourceType.Online,
    },
    {
        label: 'Offline',
        value: DonationsReportSourceType.Offline,
    },
    {
        label: 'Matching',
        value: DonationsReportSourceType.Matching,
    },
    {
        label: 'Facebook',
        value: DonationsReportSourceType.Facebook,
    },
];
