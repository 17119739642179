import { gql } from '@apollo/client';
/**
 * Setup the **Facebook Developer App** credentials.
 * @ticket https://8base-dev.atlassian.net/browse/VSL-689
 */
export const SETUP_FACEBOOK_INTEGRATION = gql `
  mutation SetupFacebookIntegration(
    $client: String!
    $clientSecret: String!
    $organization: VizslaOrganizationKeyFilter!
  ) {
    commit: setupFacebookIntegration(
      client: $client
      clientSecret: $clientSecret
      organization: $organization
    ) {
      success
    }
  }
`;
