import { gql } from '@apollo/client';
import { FILE_URL_FRAGMENT } from '../file';
import { USER_FRAGMENT } from '../users';
import { INDIVIDUAL_FUNDRAISING_CONTENT_FRAGMENT, INDIVIDUAL_FUNDRAISING_GALLERY_FRAGMENT, INDIVIDUAL_FUNDRAISING_HEADER_FRAGMENT, } from './individualFundraising';
export const INDIVIDUAL_FUNDRAISING_MANAGEMENT_FRAGMENT = gql `
  fragment INDIVIDUAL_FUNDRAISING_MANAGEMENT_FRAGMENT on IndividualFundraising {
    id
    title
    description
    goal
    raised
    webLink

    logo {
      ...FileURLFragment
    }

    hero {
      ...FileURLFragment
    }

    allocatedDonations {
      items {
        id
        amount
      }
    }

    gallery {
      items {
        ...FileURLFragment
      }
    }

    attendee {
      id

      user {
        ...UserFragment
      }
    }
  }
`;
export const INDIVIDUAL_FUNDRAISING_MANAGEMENT_BY_FILTER = gql `
  ${INDIVIDUAL_FUNDRAISING_CONTENT_FRAGMENT}

  query IndividualFundraisingManagement($filter: IndividualFundraisingFilter!) {
    individualFundraisingsList(filter: $filter) {
      count
      items {
        ...INDIVIDUAL_FUNDRAISING_CONTENT_FRAGMENT
      }
    }
  }
`;
/// Content
export const INDIVIDUAL_FUNDRAISING_UPDATE_CONTENT = gql `
  ${INDIVIDUAL_FUNDRAISING_CONTENT_FRAGMENT}

  mutation IndividualFundraisingUpdateContent(
    $data: IndividualFundraisingUpdateInput!
    $filter: IndividualFundraisingKeyFilter!
  ) {
    individualFundraising: individualFundraisingUpdate(data: $data, filter: $filter) {
      ...INDIVIDUAL_FUNDRAISING_CONTENT_FRAGMENT
    }
  }
`;
export const INDIVIDUAL_FUNDRAISING_CREATE_CONTENT = gql `
  ${FILE_URL_FRAGMENT}
  ${USER_FRAGMENT}
  ${INDIVIDUAL_FUNDRAISING_MANAGEMENT_FRAGMENT}

  mutation IndividualFundraisingCreateContent($data: IndividualFundraisingCreateInput!) {
    individualFundraising: individualFundraisingCreate(data: $data) {
      ...INDIVIDUAL_FUNDRAISING_MANAGEMENT_FRAGMENT
    }
  }
`;
/// Gallery
export const INDIVIDUAL_FUNDRAISING_UPDATE_GALLERY = gql `
  ${FILE_URL_FRAGMENT}
  ${INDIVIDUAL_FUNDRAISING_GALLERY_FRAGMENT}

  mutation IndividualFundraisingUpdateGallery(
    $data: IndividualFundraisingUpdateInput!
    $filter: IndividualFundraisingKeyFilter!
  ) {
    individualFundraising: individualFundraisingUpdate(
      data: $data
      filter: $filter
      destroyDetached: true
    ) {
      ...INDIVIDUAL_FUNDRAISING_GALLERY_FRAGMENT
    }
  }
`;
/// Header
export const INDIVIDUAL_FUNDRAISING_UPDATE_HEADER = gql `
  ${FILE_URL_FRAGMENT}
  ${INDIVIDUAL_FUNDRAISING_HEADER_FRAGMENT}

  mutation IndividualFundraisingUpdateHeader(
    $data: IndividualFundraisingUpdateInput!
    $filter: IndividualFundraisingKeyFilter!
  ) {
    individualFundraising: individualFundraisingUpdate(
      data: $data
      filter: $filter
      destroyDetached: true
    ) {
      ...INDIVIDUAL_FUNDRAISING_HEADER_FRAGMENT
    }
  }
`;
