import { PeopleReportType } from '@vizsla/types';
export const PEOPLE_REPORT_TYPE_OPTIONS = [
    {
        label: 'Attendee',
        value: PeopleReportType.Attendee,
    },
    {
        label: 'Fundraiser',
        value: PeopleReportType.Fundraiser,
    },
    {
        label: 'Donor',
        value: PeopleReportType.Donor,
    },
];
