import { gql } from '@apollo/client';
export const FILE_URL_FRAGMENT = gql `
  fragment FileURLFragment on File {
    id
    fileId
    filename
    downloadUrl
    createdAt

    createdBy {
      id
      firstName
      lastName
    }
  }
`;
export const FILE_FRAGMENT = gql `
  fragment File on File {
    id
    fileId
    filename
    downloadUrl
    createdAt
    meta
  }
`;
export const FILE_QUERY = gql `
  query File($id: ID, $fileId: String) {
    file(id: $id, fileId: $fileId) {
      ...File
    }
  }

  ${FILE_FRAGMENT}
`;
