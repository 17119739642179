import { gql } from '@apollo/client';
export const UPDATE_PARTNERSHIP_COMMITMENT = gql `
  mutation UpdatePartnershipCommitment(
    $data: PartnershipCommitmentUpdateInput!
    $filter: PartnershipCommitmentKeyFilter!
  ) {
    partnershipCommitmentUpdate(data: $data, filter: $filter) {
      id
    }
  }
`;
export const GET_PARTNERSHIP_MATCHING_COMMITMENTS = gql `
  query GetPartnershipMatchingCommitments($campaignId: String, $experienceId: String) {
    getPartnershipMatchingCommitments(
      data: { campaignId: $campaignId, experienceId: $experienceId }
    ) {
      matchingCommitments {
        amount
        partnershipName
        campaignId
        description
        endDate
        experienceId
        id
        partnershipOrganizationId
        usedAmount
        receivedAmount
        startDate
        status
        matchLimit
        type
      }
    }
  }
`;
