import { gql } from '@apollo/client';
export const REPORT_DATASOURCE_FRAGMENT = gql `
  fragment ReportDataSource on ReportDataSource {
    id
    format
    createdAt
    updatedAt
    customConfiguration
    metadata: customConfiguration

    source {
      fileId
      downloadUrl
    }
  }
`;
export const REPORT_DATASOURCES_QUERY = gql `
  query ReportDataSources(
    $filter: ReportDataSourceFilter
    $first: Int
    $skip: Int
    $orderBy: [ReportDataSourceOrderBy!]
    $sortBy: [ReportDataSourceSort!]
  ) {
    datasources: reportDataSourcesList(
      filter: $filter
      first: $first
      skip: $skip
      orderBy: $orderBy
      sort: $sortBy
    ) {
      count
      items {
        ...ReportDataSource
      }
    }
  }

  ${REPORT_DATASOURCE_FRAGMENT}
`;
