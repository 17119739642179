import { gql } from '@apollo/client';
export const REPORT_FRAGMENT = gql `
  fragment Report on Report {
    id
    status
    description
    createdAt
    updatedAt
    customConfiguration
    createdBySystem

    folder {
      id
      name
    }

    organization {
      id
      name
    }

    template {
      id
      name
    }

    author: createdBy {
      id
      fullName
    }
  }
`;
export const REPORT_QUERY = gql `
  query Report($id: ID!) {
    report(id: $id) {
      ...Report
    }
  }

  ${REPORT_FRAGMENT}
`;
export const REPORTS_QUERY = gql `
  query Reports(
    $filter: ReportFilter
    $first: Int
    $skip: Int
    $orderBy: [ReportOrderBy!]
    $sortBy: [ReportSort!]
  ) {
    reports: reportsList(
      filter: $filter
      first: $first
      skip: $skip
      orderBy: $orderBy
      sort: $sortBy
    ) {
      count
      items {
        ...Report
      }
    }
  }

  ${REPORT_FRAGMENT}
`;
export const REPORT_UPDATE_MUTATION = gql `
  mutation ReportUpdate($filter: ReportKeyFilter, $data: ReportUpdateInput!) {
    report: reportUpdate(filter: $filter, data: $data) {
      ...Report
    }
  }

  ${REPORT_FRAGMENT}
`;
export const REPORT_REMOVE_MUTATION = gql `
  mutation ReportRemove($filter: ReportKeyFilter, $force: Boolean) {
    commit: reportDelete(filter: $filter, force: $force) {
      success
    }
  }
`;
